import React from "react";
import * as classes from "./index.module.less";
import Layout from "components/shared/Layout";
import Card from "components/shared/Card";
import console from "images/svg_icons/console.svg";
import certificate from "images/svg_icons/certificate.svg";
import startup from "images/svg_icons/startup.svg";
import store from "images/svg_icons/shop.svg";
import verified from "images/svg_icons/verified.svg";

export default () => (
  <div className={classes.container}>
    <Layout>
      <h3>Our Distribution Solutions</h3>
      <div className={classes.cards}>
        <Card
          title="App Store Publishing"
          href="/blog/how-to-publish-my-app-in-china/"
          align="verticalreverse"
          content={
            <p>
              Our international, English-speaking team will walk you through the
              process of launching your app on the major Chinese app and game
              stores.
            </p>
          }
          img={startup}
        />
        <Card
          title="Game Licenses & Distribution"
          href="/blog/how-to-publish-your-game-in-china/"
          content={
            <p>
              Publishing your game in China requires special licenses and
              considerations. We will help you navigate the additional
              restrictions & requirements.
            </p>
          }
          img={console}
        />
        <Card
          title="Copyright Certificate"
          href="/services/distribution/copyright-certificate/"
          content={
            <p>
              A Software Copyright Certificate is required to publish your app
              or game in China. We will acquire one for you so you retain all
              rights.
            </p>
          }
          img={certificate}
        />
        <Card
          title="App & Game Store Optimization"
          href="/services/distribution/app-store-optimization/"
          content={
            <p>
              Chinese app stores operate differently from Google Play. Right
              from launch, we know the tricks and techniques to get your app
              maximum exposure.
            </p>
          }
          img={store}
        />
        <Card
          title="Reclaiming & Protection"
          href="/services/distribution/reclaiming/"
          content={
            <p>
              We can continuously monitor the Chinese app stores for
              unauthorized or hacked versions of your game or app and remove
              them for you.
            </p>
          }
          img={verified}
        />
      </div>
    </Layout>
  </div>
);
