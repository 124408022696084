import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import CTA from "components/shared/CTA";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/users.svg";
import { StaticImage } from "gatsby-plugin-image";
import ImageBlock from "components/services/ImageBlock";
import DistributionGrid from "components/services/DistributionGrid";

export default () => (
  <>
    <Meta url="/services/distribution/" />
    <Nav />
    <LargeTitle
      img={icon}
      green="Distribution"
      black="Services"
      text="We publish and maintain your iOS and Android apps and games on China's Apple App Store and top Android app stores, so you can get a piece of this $37+ billion market."
    />
    <ImageBlock
      title="Reach 1.1 billion more users"
      text={
        <>
          <p>
            In China, there is no dominant Android app store like Google Play.
            You must individually submit your app to each store for approval.
            This can be tough, especially if you don't speak Chinese.
          </p>
          <p>
            And if you're publishing a game, there are even more hoops and
            regulations to jump through.
          </p>
          <p>
            But AppInChina does all the hard work for you - from distribution to
            licensing and protecting your IP.
          </p>
        </>
      }
      img={
        <StaticImage
          src="../../images/photos/chinese-people.jpg"
          alt="Chinese People"
          placeholder="blurred"
          layout="fixed"
          width={800}
          height={650}
        />
      }
    />
    <DistributionGrid />
    <CTA />
    <Footer />
  </>
);
